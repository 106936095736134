import { Box, Typography } from '@material-ui/core'

import { useNoResultsBannerStyles } from './hooks'

import NO_RESULTS_IMAGE from '../../../../../assets/no-results-banner.svg'

export const NoResultsBanner = () => {
  const { classes } = useNoResultsBannerStyles()

  return (
    <Box className={classes.container}>
      <img src={NO_RESULTS_IMAGE} alt="No results found" />
      <Box className={classes.textContainer}>
        <Typography className={classes.headerText}>Sorry, no matching results</Typography>
        <Typography className={classes.descriptionText}>
          Please adjust your filters or modify your search request for better results.
        </Typography>
      </Box>
    </Box>
  )
}
