import { Box, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { OnlineConsultationRequiredDialog } from './components'
import { useMaximumStartingPriceFilterStyles } from './hooks'

import { InputField } from '../../../../../../../InputField'

import {
  selectConsultationFilterOption,
  selectMaxStartPriceFilterOption,
  setMaxStartPriceFilterOption
} from '../../../../../../../../../containers/mui/SearchPage/duck'

export const MaximumStartingPriceFilter = () => {
  const { classes } = useMaximumStartingPriceFilterStyles()
  const maxStartPriceFilterOption = useSelector(selectMaxStartPriceFilterOption)
  const consultationFilterOption = useSelector(selectConsultationFilterOption)
  const dispatch = useDispatch()

  const changeHandler = (value) => {
    dispatch(setMaxStartPriceFilterOption(value > 0 ? value : undefined))
  }

  if (consultationFilterOption.category === 'medical-aesthetics') {
    return <OnlineConsultationRequiredDialog consultation={consultationFilterOption.name} />
  }

  return (
    consultationFilterOption.id !== '' && (
      <Box className={classes.container}>
        <Box className={classes.textContainer}>
          <Typography className={classes.headerText}>Maximum starting price, $</Typography>
          <Typography className={classes.descriptionText}>
            {`Set your maximum starting price for ${consultationFilterOption.name} consultations.`}
          </Typography>
        </Box>
        <InputField
          placeholder="Max starting price"
          variant="outlined"
          type="number"
          value={maxStartPriceFilterOption > 0 && maxStartPriceFilterOption}
          onChange={changeHandler}
          validate={(value) => {
            if (value && value < 0.01) {
              return 'Price must be greater than or equal to $0.01'
            }
            return ''
          }}
          isError={!maxStartPriceFilterOption || maxStartPriceFilterOption < 0.01}
          InputProps={{
            className: classes.inputFieldContainer,
            classes: {
              input: classes.placeholderText
            },
            style: {
              fontSize: '16px'
            }
          }}
          inputProps={{ min: 0 }}
        />
      </Box>
    )
  )
}
