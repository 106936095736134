import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Backdrop, Box, Fade, Modal, Typography } from '@material-ui/core'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  MaximumStartingPriceFilter,
  SpecialisationFilters,
  SpecialOfferFilters
} from './components'
import { useFiltersModalStyles } from './hooks'

import { Button } from '../../../../../Button'
import { Icon } from '../../../../../Icon'

import {
  clearFilterOptions,
  clearSortingOptions,
  fetchNumberOfPotentiallyRelevantListings,
  fetchPractitionerListings,
  selectFilterOptions,
  selectPotentiallyRelevantListingsNumber,
  setFilterOptions
} from '../../../../../../../containers/mui/SearchPage/duck'

export const FiltersModal = ({ isOpen, onClose }) => {
  const { classes } = useFiltersModalStyles()
  const filterOptions = useSelector(selectFilterOptions)
  const potentiallyRelevantListingsNumber = useSelector(selectPotentiallyRelevantListingsNumber)
  const dispatch = useDispatch()
  const initialFilters = useRef([])

  useEffect(() => {
    if (isOpen) {
      initialFilters.current = filterOptions
    }
  }, [isOpen])

  useEffect(() => {
    dispatch(fetchNumberOfPotentiallyRelevantListings())
  }, [filterOptions])

  const clearAllClickHandler = () => {
    dispatch(clearFilterOptions())
    dispatch(clearSortingOptions())
    onClose()
    dispatch(fetchPractitionerListings())
  }

  const showPractitionersClickHandler = () => {
    dispatch(fetchPractitionerListings())
    onClose()
  }

  const closeHandler = () => {
    dispatch(setFilterOptions(initialFilters.current))
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      onClose={closeHandler}
      className={classes.container}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Box className={classes.paper}>
          <Box className={classes.headerAndFiltersContainer}>
            <Box className={classes.headerContainer}>
              <Typography className={classes.headerText}>Filter</Typography>
              <Button uiType="tertiary" onClick={closeHandler} className={classes.closeButton}>
                <Icon icon={faXmark} className={classes.crossIcon} />
              </Button>
            </Box>
            <Box className={classes.filtersContainer}>
              <SpecialOfferFilters />
              {/* <ExperienceFilters /> */}
              {/* <AreaFilters /> */}
              <SpecialisationFilters />
              <MaximumStartingPriceFilter />
            </Box>
          </Box>
          <Box className={classes.buttonsContainer}>
            <Button
              uiType="tertiary"
              className={classes.clearAllButton}
              onClick={clearAllClickHandler}
            >
              <Typography className={classes.buttonText}>Clear all</Typography>
            </Button>
            <Button
              uiType="primary"
              className={classes.findPractitionersButton}
              onClick={showPractitionersClickHandler}
            >
              <Typography
                className={classes.buttonText}
              >{`Show ${potentiallyRelevantListingsNumber} ${potentiallyRelevantListingsNumber > 1 ? 'practitioners' : 'practitioner'}`}</Typography>
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}
