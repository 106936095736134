import { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { useSelector, useDispatch } from 'react-redux'

import { useSpecialisationFiltersStyles } from './hooks'
import { SpecialisationInputField } from './components'

import { FilterCheckbox } from '../FilterCheckbox'

import { specializations } from '../../../../../../../utils/practitionerListing'
import { Button } from '../../../../../../../Button'
import { highlightMatchingSubstring } from '../../../../../../../../../util/new/string'
import {
  selectSpecialisationFilterOptions,
  setSpecialisationFilterOptions
} from '../../../../../../../../../containers/mui/SearchPage/duck'

const MAX_NUM_OF_ITEMS_IN_COLLAPSED_LIST = 7

export const SpecialisationFilters = () => {
  const dispatch = useDispatch()
  const specialisation = useSelector(selectSpecialisationFilterOptions)
  const [inputValue, setInputValue] = useState('')
  const [matchingSpecialisations, setMatchingSpecialisations] = useState()
  const [renderAllFilters, setRenderAllFilters] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const { classes } = useSpecialisationFiltersStyles()

  useEffect(() => {
    setMatchingSpecialisations(
      specializations
        .filter(({ displayValue }) => displayValue.toLowerCase().includes(inputValue.toLowerCase()))
        .map(({ value, displayValue }) => {
          const clickHandler = (value) => {
            const updatedOptions = specialisation.includes(value)
              ? specialisation.filter((item) => item !== value)
              : [...specialisation, value]

            dispatch(setSpecialisationFilterOptions(updatedOptions))
          }

          return (
            <FilterCheckbox
              displayValue={ReactHtmlParser(highlightMatchingSubstring(displayValue, inputValue))}
              value={value}
              onClick={clickHandler}
              isChecked={specialisation.includes(value)}
              key={value}
            />
          )
        })
        .slice(
          0,
          !renderAllFilters && inputValue.length === 0
            ? MAX_NUM_OF_ITEMS_IN_COLLAPSED_LIST
            : undefined
        )
    )
  }, [inputValue, renderAllFilters, specialisation])

  const changeHandler = (text) => {
    return setInputValue(text)
  }

  const showMoreClickHandler = () => {
    setRenderAllFilters((prevValue) => !prevValue)
  }

  const focusHandler = () => {
    setIsFocused(true)
  }

  const blurHandler = () => {
    setIsFocused(false)
  }

  const clearHandler = () => {
    setInputValue('')
  }

  const shouldRenderShowMoreButton = inputValue.length === 0

  return (
    <Box className={classes.container}>
      <Typography className={classes.headerText}>Specialisations</Typography>
      <Box className={classes.inputFieldAndFiltersContainer}>
        <SpecialisationInputField
          placeholder="Search"
          value={inputValue}
          setValue={setInputValue}
          isFocused={isFocused}
          onChange={changeHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onClear={clearHandler}
        />
        <Box className={classes.filterCheckboxesAndButtonContainer}>
          <Box className={classes.filterCheckboxesContainer}>{matchingSpecialisations}</Box>
          {shouldRenderShowMoreButton && (
            <Button
              uiType="tertiary"
              className={classes.showMoreOrLessButton}
              onClick={showMoreClickHandler}
            >
              <Typography className={classes.showMoreOrLessButtonText}>
                {renderAllFilters
                  ? 'Show less'
                  : `Show ${specializations.length - MAX_NUM_OF_ITEMS_IN_COLLAPSED_LIST} more`}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
