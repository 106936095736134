import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '32px'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  leftButtonContainer: {
    flex: '1 1 auto', // Takes up available space to the left
    display: 'flex',
    justifyContent: 'flex-start'
  },
  rightButtonContainer: {
    flex: '1 1 auto', // Takes up available space to the right
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

export const useListViewStyles = () => {
  const classes = useStyles()
  return { classes }
}
