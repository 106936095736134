import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginBottom: '16px'
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 500,
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    descriptionText: {
      fontSize: '14px',
      lineHeight: '20px',
      color: palette.blue['300'],
      padding: 0,
      letterSpacing: 0
    },
    inputFieldContainer: {
      width: '340px',
      height: '42px'
    },
    placeholderText: {
      '&::placeholder': {
        fontSize: '16px',
        padding: 0,
        letterSpacing: 0
      }
    }
  }
})

export const useMaximumStartingPriceFilterStyles = () => {
  const classes = useStyles()
  return { classes }
}
