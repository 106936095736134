import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 500,
      color: palette.blue['800']
    },
    filterCheckboxesAndButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    },
    filterCheckboxesContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    showMoreOrLessButton: {
      width: '125px',
      height: '42px'
    },
    showMoreOrLessButtonText: {
      fontSize: '15px',
      fontWeight: 500,
      color: palette.primary.main,
      padding: 0,
      letterSpacing: 0
    },
    inputFieldAndFiltersContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    }
  }
})

export const useSpecialisationFiltersStyles = () => {
  const classes = useStyles()
  return { classes }
}
