import { MenuItem, Select, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import { useSortOptionsDropdownStyles } from './hooks'

import {
  selectSortOption,
  setSortOption
} from '../../../../../../../containers/mui/SearchPage/duck'

export const SortOptionsDropdown = () => {
  const { classes } = useSortOptionsDropdownStyles()
  const dispatch = useDispatch()
  const sortOption = useSelector(selectSortOption)

  const changeHandler = (event) => {
    const { value } = event.target
    dispatch(setSortOption(value))
  }

  return (
    <Select
      variant="outlined"
      value={sortOption}
      onChange={changeHandler}
      className={classes.container}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        PaperProps: {
          className: classes.dropdownContainer
        }
      }}
    >
      <MenuItem
        value="recommended"
        className={classNames(
          classes.menuItemContainer,
          sortOption === 'recommended' && classes.menuItemContainerSelected
        )}
      >
        <Typography className={classes.text}>Recommended</Typography>
      </MenuItem>
      <MenuItem
        value="topRated"
        className={classNames(
          classes.menuItemContainer,
          sortOption === 'topRated' && classes.menuItemContainerSelected
        )}
      >
        <Typography className={classes.text}>Top-rated</Typography>
      </MenuItem>
      <MenuItem
        value="nearest"
        className={classNames(
          classes.menuItemContainer,
          sortOption === 'nearest' && classes.menuItemContainerSelected
        )}
      >
        <Typography className={classes.text}>Nearest</Typography>
      </MenuItem>
    </Select>
  )
}
