import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    filterChipsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '12px',
      [breakpoints.down('sm')]: {
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }
    },
    clearAllButton: {
      width: '61px',
      height: '32px',
      fontSize: '13px',
      lineHeight: '22px',
      fontWeight: 500,
      color: palette.primary.main,
      letterSpacing: 0,
      padding: 0,
      '&:hover': {
        backgroundColor: palette.primary.light
      },
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        whiteSpace: 'wrap',
        lineHeight: '12px'
      }
    },
    filterChipContainer: {
      borderColor: palette.primary.light
    },
    filterChipLabelContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px'
    },
    filterChipLabelLeft: {
      color: palette.text.disabled,
      fontSize: '13px',
      lineHeight: '18px',
      padding: 0,
      letterSpacing: 0
    },
    filterChipLabelRight: {
      color: palette.blue['800'],
      fontSize: '13px',
      lineHeight: '18px',
      padding: 0,
      letterSpacing: 0
    }
  }
})

export const useFilterChipsSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
