import { Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { useListViewStyles } from './hooks'

import { PractitionerListing } from '../PractitionerListing'

import {
  fetchPractitionerListings,
  selectCurrentPage,
  selectListings,
  selectLocationFilterOption,
  selectNbPages
} from '../../../../../containers/mui/SearchPage/duck'
import { Button } from '../../../Button'

export const ListView = () => {
  const { classes } = useListViewStyles()
  const dispatch = useDispatch()
  const listings = useSelector(selectListings)
  const currentPage = useSelector(selectCurrentPage)
  const nbPages = useSelector(selectNbPages)
  const hasPrevPage = currentPage > 0
  const hasNextPage = currentPage < nbPages - 1
  const searchLocation = useSelector(selectLocationFilterOption)

  const locationCoordinates = { lat: searchLocation.lat, lon: searchLocation.lon }

  const renderListings = () => {
    return listings.map((listing) => {
      return (
        <PractitionerListing listing={listing} key={listing.id} location={locationCoordinates} />
      )
    })
  }

  const fetchPrevResultsHandler = () => {
    dispatch(fetchPractitionerListings(currentPage - 1))
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' }) // Scroll to the top smoothly
    }
  }

  const fetchMoreResultsHandler = () => {
    dispatch(fetchPractitionerListings(currentPage + 1))
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' }) // Scroll to the top smoothly
    }
  }

  return (
    <Box className={classes.container}>
      {renderListings()}
      <Box className={classes.paginationContainer}>
        {hasPrevPage && (
          <Box className={classes.leftButtonContainer}>
            <Button uiType="tertiary" onClick={fetchPrevResultsHandler}>
              View Previous
            </Button>
          </Box>
        )}
        {hasNextPage && (
          <Box className={classes.rightButtonContainer}>
            <Button uiType="tertiary" onClick={fetchMoreResultsHandler}>
              View More
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
