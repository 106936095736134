import '../../../shared/mapbox-gl.css'

import mapboxgl from 'mapbox-gl'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Marker, PractitionerListingCard } from './components'
import { useMapViewStyles } from './hooks'
import { mapBoundingBoxCoordinatesToAlgoliaFormat } from './utils'

import {
  fetchPractitionerListingsOnMap,
  selectListings,
  selectLocationFilterOption
} from '../../../../../containers/mui/SearchPage/duck'
import { selectFavouriteListingIds } from '../../../../../ducks/user.duck'

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
mapboxgl.accessToken = mapboxAccessToken

// Memoized Marker Component
const MemoizedMarker = React.memo(({ isActive, listing, isFavourite }) => {
  return <Marker isActive={isActive} listing={listing} isFavourite={isFavourite} />
})

export const MapView = ({ currentView }) => {
  const dispatch = useDispatch()
  const listings = useSelector(selectListings)
  const searchLocation = useSelector(selectLocationFilterOption)
  const favouriteListingIds = useSelector(selectFavouriteListingIds)
  const history = useHistory()
  const mapContainerRef = useRef()
  const mapRef = useRef()
  const popupRef = useRef(null)
  const [selectedListing, setSelectedListing] = useState({ id: '' })

  const { classes } = useMapViewStyles()

  const favoritesButtonClickHandler = () => {}

  const outsidePopupClickHandler = useCallback((event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSelectedListing({ id: '' })
      popupRef.current = null
    }
  }, [])

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [searchLocation.lon, searchLocation.lat],
      zoom: 11
    })

    // Zoom control
    mapRef.current.addControl(new mapboxgl.NavigationControl())

    const bounds = mapRef.current.getBounds()
    const newViewportBorders = {
      northEast: bounds.getNorthEast().toArray(),
      southWest: bounds.getSouthWest().toArray()
    }

    document.addEventListener('mousedown', outsidePopupClickHandler)

    const algoliaBoundingBoxCoordinates =
      mapBoundingBoxCoordinatesToAlgoliaFormat(newViewportBorders)
    dispatch(fetchPractitionerListingsOnMap(algoliaBoundingBoxCoordinates))

    return () => {
      document.removeEventListener('mousedown', outsidePopupClickHandler)
    }
  }, [dispatch, searchLocation, currentView, outsidePopupClickHandler])

  useEffect(() => {
    // Keep track of the currently open popup and marker
    let currentPopup = null
    let currentMarker = null

    listings.forEach((listing) => {
      const { lat, lon } = listing.location.coordinates

      const markerNode = document.createElement('div')
      ReactDOM.render(
        <MemoizedMarker
          isActive={selectedListing.id === listing.id}
          listing={listing}
          isFavourite={favouriteListingIds.includes(listing.id)}
        />,
        markerNode
      )

      const popupNode = document.createElement('div')

      const marker = new mapboxgl.Marker(markerNode).setLngLat([lon, lat]).addTo(mapRef.current)

      const showPopup = () => {
        // Close the currently open popup and reset the marker if they exist
        if (currentPopup && currentMarker) {
          currentPopup.remove()
          currentMarker.getElement().classList.remove('active-marker') // Optional: add a class for active markers
        }

        // Set the new selected listing and update the marker and popup references
        setSelectedListing({ id: listing.id })
        currentMarker = marker

        ReactDOM.render(
          <PractitionerListingCard
            listing={listing}
            favouriteListingIds={favouriteListingIds}
            onFavouritesButtonClick={favoritesButtonClickHandler}
            history={history}
          />,
          popupNode
        )

        const popup = new mapboxgl.Popup({ offset: 25 })
          .setMaxWidth('700px')
          .setDOMContent(popupNode)

        popup.on('open', () => {
          const closeButton = document.querySelector('.mapboxgl-popup-close-button')
          if (closeButton) {
            closeButton.style.display = 'none'
          }
          popupRef.current = popupNode
        })

        marker.setPopup(popup).togglePopup()
        currentPopup = popup
      }

      markerNode.addEventListener('click', showPopup)
    })

    const updateViewportBorders = () => {
      const bounds = mapRef.current.getBounds()
      const newViewportBorders = {
        northEast: bounds.getNorthEast().toArray(),
        southWest: bounds.getSouthWest().toArray()
      }

      const algoliaBoundingBoxCoordinates =
        mapBoundingBoxCoordinatesToAlgoliaFormat(newViewportBorders)
      dispatch(fetchPractitionerListingsOnMap(algoliaBoundingBoxCoordinates))
    }

    mapRef.current.on('moveend', updateViewportBorders)

    return () => {
      mapRef.current.off('moveend', updateViewportBorders)
      if (currentPopup) {
        currentPopup.remove() // Clean up any open popups when the component unmounts
      }
    }
  }, [
    dispatch,
    listings,
    currentView,
    selectedListing,
    favouriteListingIds,
    history,
    favoritesButtonClickHandler
  ])

  return <div className={classes.container} ref={mapContainerRef} />
}
