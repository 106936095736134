import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints } = theme

  return {
    container: {
      width: '100%',
      height: '70vh',
      position: 'relative',
      [breakpoints.down('sm')]: {
        width: '100%',
        height: '60vh'
      }
    }
  }
})

export const useMapViewStyles = () => {
  const classes = useStyles()
  return { classes }
}
