import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      height: '42px'
    },
    placeholderText: {
      '&::placeholder': {
        fontSize: '16px'
      }
    },
    magnifyingGlassIcon: {
      width: '12px',
      height: '16px',
      color: palette.blue['800']
    },
    xMarkIcon: {
      width: '12px',
      height: '16px',
      color: palette.blue['800'],
      '&:hover': {
        cursor: 'pointer',
        color: palette.primary.main
      }
    }
  }
})

export const useSpecialisationInputFieldStyles = () => {
  const classes = useStyles()
  return { classes }
}
