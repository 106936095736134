import { Box, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import { useSpecialOfferFiltersStyles } from './hooks'

import { FilterChip } from '../FilterChip'

import { specialOffers } from '../../../../../../../utils/practitionerListing'

import {
  selectSpecialOfferFilterOptions,
  setSpecialOfferFilterOptions
} from '../../../../../../../../../containers/mui/SearchPage/duck'

export const SpecialOfferFilters = () => {
  const { classes } = useSpecialOfferFiltersStyles()
  const specialOffer = useSelector(selectSpecialOfferFilterOptions)
  const dispatch = useDispatch()

  const clickHandler = (value) => {
    const updatedSpecialOffer = specialOffer.includes(value)
      ? specialOffer.filter((item) => item !== value)
      : [...specialOffer, value]
    dispatch(setSpecialOfferFilterOptions(updatedSpecialOffer))
  }

  const renderSpecialOfferChips = () => {
    return specialOffers.map(({ value, displayValue }) => {
      return (
        <FilterChip
          displayValue={displayValue}
          value={value}
          onClick={clickHandler}
          isSelected={specialOffer.includes(value)}
          key={value}
        />
      )
    })
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.headerText}>Special offers</Typography>
      <Box className={classes.filterChipsContainer}>{renderSpecialOfferChips()}</Box>
    </Box>
  )
}
