import { Box } from '@material-ui/core'
import haversine from 'haversine-distance'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  BookConsultationButton,
  DetailedInfoSection,
  FavoritesButton,
  GeneralInfoSection,
  ImageCarouselSection,
  OfferChipsSection,
  ScheduleSection,
  TreatmentChipsSection
} from './components'
import { usePractitionerListingStyles } from './hooks'

import { selectCurrentUser, selectFavouriteListingIds } from '../../../../../ducks/user.duck'

const getDistance = (pointA, pointB) => {
  const distanceInMeters = haversine(pointA, pointB)
  const distanceInKilometers = distanceInMeters / 1000

  if (distanceInKilometers < 1) {
    return '<1 km'
  }

  return `${Math.round(distanceInKilometers)} km`
}

export const PractitionerListing = ({ listing, coverLetter, type = 'listing', location }) => {
  const favouriteListingIds = useSelector(selectFavouriteListingIds)
  const { classes } = usePractitionerListingStyles()
  const currentUser = useSelector(selectCurrentUser)
  const history = useHistory()
  const [isFavoritesButtonActive, setIsFavoritesButtonActive] = useState(
    favouriteListingIds.includes(listing.id)
  )

  useEffect(() => {
    setIsFavoritesButtonActive(favouriteListingIds.includes(listing.id))
  }, [favouriteListingIds])

  const {
    id,
    clinicName,
    practitionerName,
    practitionerId,
    abbreviatedPractitionerName,
    practitionerPhoto,
    averageRating,
    reviewsCount,
    schedule,
    title,
    description,
    location: practitionerLocation,
    experience,
    specializations,
    searchBookingUrl,
    photos,
    offers,
    state
  } = listing

  const listingClickHandler = () => {
    history.push(`/l/${id}`)
  }

  const practitionerCoordinates = practitionerLocation.coordinates
  const distanceToPractitioner = getDistance(practitionerCoordinates, location)

  const shouldRenderFavouritesButton = currentUser?.id?.uuid !== practitionerId

  return (
    <Box className={classes.container}>
      <Box className={classes.mainContentContainer} onClick={listingClickHandler}>
        <Box className={classes.practitionerInfoContainer}>
          <Box className={classes.topSectionContainer}>
            <Box className={classes.generalInfoContainer}>
              <GeneralInfoSection
                profilePhotoUrl={practitionerPhoto}
                clinicName={clinicName}
                practitionerName={practitionerName}
                abbreviatedPractitionerName={abbreviatedPractitionerName}
                averageRating={averageRating}
                reviewsCount={reviewsCount}
                listingId={id}
                state={state}
              />
              <OfferChipsSection offers={offers} />
            </Box>
            {shouldRenderFavouritesButton && (
              <FavoritesButton isActive={isFavoritesButtonActive} listingId={id} />
            )}
          </Box>
          <Box className={classes.detailedInfoContainer}>
            <DetailedInfoSection
              experience={experience}
              address={practitionerLocation.name.address.split(', ')[1]}
              distance={distanceToPractitioner}
              descriptionTitle={title}
              descriptionText={type === 'concernReply' ? coverLetter || description : description}
              type={type}
            />
            <TreatmentChipsSection treatments={specializations || []} />
          </Box>
          <Box className={classes.desktopBookConsultationButtonContainer}>
            <BookConsultationButton href={searchBookingUrl} listingId={id} />
          </Box>
        </Box>
        <Box className={classes.imagesAndScheduleContainer}>
          <ImageCarouselSection images={photos} />
          <ScheduleSection schedule={schedule} />
        </Box>
      </Box>
      <Box className={classes.mobileBookConsultationButtonContainer}>
        <BookConsultationButton href={searchBookingUrl} listingId={id} />
      </Box>
    </Box>
  )
}
