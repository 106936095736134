import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    root: {
      margin: 0,
      padding: 0
    },
    label: {
      margin: 0,
      padding: 0,
      fontSize: '16px',
      color: palette.blue['800'],
      letterSpacing: 0
    },
    checkboxRoot: {
      color: palette.text.disabled
    }
  }
})

export const useFilterCheckboxStyles = () => {
  const classes = useStyles()
  return { classes }
}
