import { InputAdornment } from '@material-ui/core'
import { faXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { oneOf, string, func, bool } from 'prop-types'

import { useSpecialisationInputFieldStyles } from './hooks'

import { Icon } from '../../../../../../../../../Icon'
import { InputField } from '../../../../../../../../../InputField'

export const SpecialisationInputField = ({
  variant = 'outlined',
  value,
  setValue,
  placeholder,
  isFocused,
  onChange,
  onFocus,
  onBlur,
  onClear
}) => {
  const { classes } = useSpecialisationInputFieldStyles()

  const clearMouseDownHandler = (event) => {
    event.preventDefault()
  }

  return (
    <InputField
      variant={variant}
      placeholder={placeholder}
      value={value}
      setValue={setValue}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={{
        className: classes.container,
        classes: {
          input: classes.placeholderText
        },
        style: {
          fontSize: '16px'
        },
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon={faMagnifyingGlass} className={classes.magnifyingGlassIcon} />
          </InputAdornment>
        ),
        endAdornment: isFocused && value && (
          <InputAdornment position="end" onClick={onClear}>
            <Icon
              icon={faXmark}
              className={classes.xMarkIcon}
              onMouseDown={clearMouseDownHandler}
            />
          </InputAdornment>
        )
      }}
      fullWidth
    />
  )
}

SpecialisationInputField.defaultProps = {
  value: '',
  placeholder: '',
  variant: 'outlined',
  isFocused: false,
  setValue: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onClear: null
}

SpecialisationInputField.propTypes = {
  value: string,
  placeholder: string,
  variant: oneOf(['standard', 'outlined', 'filled']),
  isFocused: bool,
  setValue: func,
  onChange: func,
  onFocus: func,
  onBlur: func,
  onClear: func
}
