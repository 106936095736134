import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    },
    text: {
      fontSize: '14px',
      lineHeight: '20px',
      padding: 0,
      letterSpacing: 0,
      color: palette.blue['300']
    },
    learnMoreButton: {
      height: '42px',
      width: '104px'
    },
    learnMoreButtonText: {
      fontSize: '15px',
      fontWeight: 500,
      color: palette.primary.main,
      padding: 0,
      letterSpacing: 0
    }
  }
})

export const useOnlineConsultationRequiredDialogStyles = () => {
  const classes = useStyles()
  return { classes }
}
