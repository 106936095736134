import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints, palette } = theme

  return {
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '20px',
      paddingBottom: '32px',
      borderBottom: `1px solid ${palette.grey['200']}`,
      [breakpoints.down('sm')]: {
        paddingBottom: '24px'
      }
    },
    mainContentContainer: {
      display: 'flex',
      gap: '20px',
      width: '100%',
      maxWidth: '1490px',
      '&:hover': {
        cursor: 'pointer'
      },
      [breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    practitionerInfoContainer: {
      display: 'flex',
      flexBasis: '63%',
      flexDirection: 'column',
      gap: '16px',
      [breakpoints.down('md')]: {
        flexBasis: '100%'
      }
    },
    topSectionContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    generalInfoContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    detailedInfoContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '12px',
      [breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    imagesAndScheduleContainer: {
      display: 'flex',
      gap: '20px',
      flexGrow: 1,
      [breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    desktopBookConsultationButtonContainer: {
      display: 'flex',
      [breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    mobileBookConsultationButtonContainer: {
      display: 'none',
      [breakpoints.down('xs')]: {
        display: 'flex'
      }
    }
  }
})

export const usePractitionerListingStyles = () => {
  const classes = useStyles()
  return { classes }
}
