import { Checkbox, FormControlLabel } from '@material-ui/core'

import { useFilterCheckboxStyles } from './hooks'

export const FilterCheckbox = ({ displayValue, value, onClick, isChecked }) => {
  const { classes } = useFilterCheckboxStyles()

  const clickHandler = () => {
    onClick(value)
  }

  return (
    <FormControlLabel
      label={displayValue}
      control={
        <Checkbox
          checked={isChecked}
          onClick={clickHandler}
          classes={{
            root: classes.checkboxRoot
          }}
          color="primary"
        />
      }
      classes={{
        root: classes.root,
        label: classes.label
      }}
    />
  )
}
