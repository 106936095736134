import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      backgroundColor: ({ isSelected }) =>
        isSelected ? palette.primary.main : palette.common.white,
      borderColor: ({ isSelected }) => (isSelected ? palette.primary.main : palette.grey['400']),
      '&:hover': {
        borderColor: ({ isSelected }) => (isSelected ? palette.primary.dark : palette.rose['300']),
        backgroundColor: ({ isSelected }) =>
          isSelected ? palette.primary.dark : palette.primary.light
      },
      '&:focus': {
        borderColor: palette.primary.main,
        backgroundColor: ({ isSelected }) =>
          isSelected ? palette.primary.main : palette.grey['200']
      }
    },
    labelText: {
      fontSize: '13px',
      lineHeight: '18px',
      color: ({ isSelected }) => (isSelected ? palette.common.white : palette.blue['800']),
      padding: 0,
      letterSpacing: 0
    }
  }
})

export const useFilterChipStyles = ({ isSelected }) => {
  const classes = useStyles({ isSelected })
  return { classes }
}
