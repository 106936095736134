import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    text: {
      fontSize: '16px',
      color: palette.blue['800'],
      padding: 0,
      margin: 0,
      letterSpacing: 0
    },
    root: {
      margin: 0, // Remove margin from FormControlLabel
      padding: 0 // Remove padding from FormControlLabel
    },
    label: {
      margin: 0, // Remove margin from label
      padding: 0, // Remove padding from label
      fontSize: '16px',
      color: palette.blue['800'],
      letterSpacing: 0
    }
  }
})

export const useOnlyFavouritesSwitchStyles = () => {
  const classes = useStyles()
  return { classes }
}
