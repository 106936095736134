import { Typography } from '@material-ui/core'

import { useFilterChipStyles } from './hooks'

import { Chip } from '../../../../../../../Chip'

export const FilterChip = ({ displayValue, value, onClick, isSelected }) => {
  const { classes } = useFilterChipStyles({ isSelected })

  const clickHandler = () => {
    onClick(value)
  }

  return (
    <Chip
      label={<Typography className={classes.labelText}>{displayValue}</Typography>}
      className={classes.container}
      onClick={clickHandler}
    />
  )
}
