import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints, palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      [breakpoints.down('sm')]: {
        marginBottom: '12px'
      }
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 500,
      color: palette.blue['800']
    },
    filterChipsContainer: {
      display: 'flex',
      gap: '8px'
    }
  }
})

export const useSpecialOfferFiltersStyles = () => {
  const classes = useStyles()
  return { classes }
}
