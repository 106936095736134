import { Box, Typography } from '@material-ui/core'

import { useOnlineConsultationRequiredDialogStyles } from './hooks'

import { Button } from '../../../../../../../../../Button'

export const OnlineConsultationRequiredDialog = ({ consultation }) => {
  const { classes } = useOnlineConsultationRequiredDialogStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>
        {`As per Australian regulations, ${consultation} consultation pricing is available only through an
          online or in-person consultation.`}
      </Typography>
      <Button
        uiType="tertiary"
        className={classes.learnMoreButton}
        href="https://www.seestella.com.au/regulations"
        target="_blank"
      >
        <Typography className={classes.learnMoreButtonText}>Learn more</Typography>
      </Button>
    </Box>
  )
}
