import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    resultsCountText: {
      fontSize: '14px',
      lineHeight: '20px',
      color: palette.text.disabled,
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
      }
    },
    sortAndFilterButtonsContainer: {
      display: 'flex',
      gap: '16px',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    sortButtonContainer: {
      height: '42px',
      width: '81px'
    },
    mobileSortAndFilterButtonContainer: {
      display: 'none',
      [breakpoints.down('sm')]: {
        display: 'flex',
        width: '52px',
        height: '52px'
      }
    },
    slidersIcon: {
      fontSize: '16px',
      width: '16px',
      height: '16px',
      color: palette.blue['800']
    },
    resultsCountAndFavouritesSwitchContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px'
    }
  }
})

export const useSortFilterSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
