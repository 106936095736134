import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 500,
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    descriptionText: {
      fontSize: '14px',
      lineHeight: '20px',
      color: palette.text.disabled,
      padding: 0,
      letterSpacing: 0
    }
  }
})

export const useNoResultsBannerStyles = () => {
  const classes = useStyles()
  return { classes }
}
