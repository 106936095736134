import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import { useOnlyFavouritesSwitchStyles } from './hooks'

import {
  selectOnlyFavouritesFilterOption,
  setOnlyFavouritesFilterOption,
  fetchPractitionerListings,
  fetchPractitionerListingsOnMap
} from '../../../../../../../containers/mui/SearchPage/duck'

export const OnlyFavouritesSwitch = ({ view }) => {
  const { classes } = useOnlyFavouritesSwitchStyles()
  const dispatch = useDispatch()
  const isChecked = useSelector(selectOnlyFavouritesFilterOption)

  const changeHandler = () => {
    dispatch(setOnlyFavouritesFilterOption(!isChecked))
    if (view === 'list') {
      dispatch(fetchPractitionerListings())
    } else {
      dispatch(fetchPractitionerListingsOnMap())
    }
  }

  return (
    <FormControlLabel
      control={<Switch checked={isChecked} onChange={changeHandler} color="primary" />}
      label={<Typography className={classes.text}>Only favourites</Typography>}
      classes={{
        root: classes.root,
        label: classes.label
      }}
    />
  )
}
