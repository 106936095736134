import { faSliders } from '@fortawesome/free-solid-svg-icons'
import { Box, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  FilterChipsSection,
  FiltersModal,
  MobileFiltersModal,
  OnlyFavouritesSwitch,
  SortOptionsDropdown
} from './components'
import { useSortFilterSectionStyles } from './hooks'

import { Button } from '../../../Button'
import { Icon } from '../../../Icon'

import {
  selectFilterOptions,
  selectListings,
  selectSearchResult
} from '../../../../../containers/mui/SearchPage/duck'
import { isAuthenticatedSelector } from '../../../../../ducks/auth.duck'

export const SortFilterSection = ({ view }) => {
  const { classes } = useSortFilterSectionStyles()
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  const [isMobileFiltersModalOpen, setIsMobileFiltersModalOpen] = useState(false)
  const listings = useSelector(selectListings)
  const searchResult = useSelector(selectSearchResult)
  const onlyFavourites = useSelector(selectFilterOptions).onlyFavourites

  const filterClickHandler = () => {
    setIsFiltersModalOpen(true)
  }

  const filtersModalCloseHandler = () => {
    setIsFiltersModalOpen(false)
  }

  const mobileFiltersModalClickHandler = () => {
    setIsMobileFiltersModalOpen(true)
  }

  const mobileFilterModalCloseHandler = () => {
    setIsMobileFiltersModalOpen(false)
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.resultsCountAndFavouritesSwitchContainer}>
          <Typography className={classes.resultsCountText}>
            {onlyFavourites
              ? `${listings.length} ${listings.length > 1 ? 'results' : 'result'}`
              : `${searchResult?.nbHits ?? 0} ${(searchResult?.nbHits ?? 0) > 1 ? 'results' : 'result'}`}
          </Typography>
          {isAuthenticated && view !== 'map' && <OnlyFavouritesSwitch view={view} />}
        </Box>
        <Box className={classes.sortAndFilterButtonsContainer}>
          {view !== 'map' && <SortOptionsDropdown />}
          <Button
            uiType="secondary"
            className={classes.sortButtonContainer}
            onClick={filterClickHandler}
          >
            Filter
          </Button>
        </Box>
        <Button
          uiType="tertiary"
          className={classes.mobileSortAndFilterButtonContainer}
          onClick={mobileFiltersModalClickHandler}
        >
          <Icon icon={faSliders} className={classes.slidersIcon} />
        </Button>
      </Box>
      <FilterChipsSection />
      <FiltersModal isOpen={isFiltersModalOpen} onClose={filtersModalCloseHandler} />
      <MobileFiltersModal
        isOpen={isMobileFiltersModalOpen}
        onClose={mobileFilterModalCloseHandler}
      />
    </>
  )
}
