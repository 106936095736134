import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      width: '180px',
      height: '42px'
    },
    text: {
      fontSize: '16px',
      padding: 0,
      letterSpacing: 0
    },
    menuItemContainer: {
      borderRadius: '4px',
      color: palette.blue['800'],
      '&:not(:first-child)': {
        marginTop: '2px'
      },
      '&:not(:last-child)': {
        marginBottom: '2px'
      },
      '&:hover': {
        backgroundColor: palette.grey['300']
      }
    },
    menuItemContainerSelected: {
      '&.Mui-selected': {
        backgroundColor: palette.primary.light,
        color: palette.primary.main
      }
    },
    dropdownContainer: {
      paddingLeft: '8px',
      paddingRight: '8px'
    }
  }
})

export const useSortOptionsDropdownStyles = () => {
  const classes = useStyles()
  return { classes }
}
