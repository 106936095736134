import { Box, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import { useExperienceFiltersStyles } from './hooks'

import { FilterChip } from '../FilterChip'

import { experienceLevels } from '../../../../../../../utils/practitionerListing'

import {
  selectExperienceFilterOptions,
  setExperienceFilterOptions
} from '../../../../../../../../../containers/mui/SearchPage/duck'

export const ExperienceFilters = () => {
  const { classes } = useExperienceFiltersStyles()
  const experience = useSelector(selectExperienceFilterOptions)
  const dispatch = useDispatch()

  const clickHandler = (value) => {
    const updatedOptions = experience.includes(value)
      ? experience.filter((item) => item !== value)
      : [...experience, value]

    dispatch(setExperienceFilterOptions(updatedOptions))
  }

  const renderExperienceChips = () => {
    return experienceLevels.map(({ value, displayValue }) => {
      return (
        <FilterChip
          displayValue={displayValue}
          value={value}
          onClick={clickHandler}
          isSelected={experience.includes(value)}
          key={value}
        />
      )
    })
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.headerText}>Experience</Typography>
      <Box className={classes.filterChipsContainer}>{renderExperienceChips()}</Box>
    </Box>
  )
}
